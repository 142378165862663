(function (c, l, a, r, i, t, y) {
  c[a] =
    c[a] ||
    function () {
      (c[a].q = c[a].q || []).push(arguments);
    };
  t = l.createElement(r);
  t.async = 1;
  t.src = "https://www.clarity.ms/tag/" + i;
  t.onerror = function () {
    console.error("Microsoft Clarity script failed to load");
  };
  y = l.getElementsByTagName(r)[0];
  y.parentNode.insertBefore(t, y);
})(window, document, "clarity", "script", "n45lzd3jpo");

// Function to set the data
function setAnalyticsData(organization, member, board) {
  if (window.clarity) {
    clarity("set", "organization", organization);
    clarity("set", "member", member);
    clarity("set", "board", board);
  } else {
    console.error("Clarity is not initialized yet.");
  }
}

// Export the setAnalyticsData function to use in other files
export { setAnalyticsData };
